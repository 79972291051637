<template>
  <!--#region template-->
  <div id="app" class="overflow-hidden">
    <router-view />
  </div>
  <!--#endregion-->
</template>

<script>
// #region script
export default {
  name: 'App',
}
// #endregion
</script>