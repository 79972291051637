export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    collection({ rootState }, payload) {
      return new Promise((resolve, reject) => {
        rootState.authentication.currentUser
          .getIdToken()
          .then((value) => {
            return fetch(`${process.env.VUE_APP_API_URL}${payload.url}?startAt=${payload.body.startAt}&limit=${payload.body.limit}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + value,
              },
            })
          })
          .then((value) => {
            if (value.status == '200') {
              return value.json()
            }
            return Promise.reject(value)
          })
          .then((value) => {
            resolve(value)
          })
          .catch((reason) => {
            // TODO implement error handling
            console.log(reason)
            reject()
          })
      })
    },
    post({ rootState }, { body, url }) {
      return new Promise((resolve, reject) => {
        rootState.authentication.currentUser
          .getIdToken()
          .then((value) => {
            return fetch(`${process.env.VUE_APP_API_URL}${url}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + value,
              },
              body: JSON.stringify(body),
            })
          })
          .then((value) => {
            resolve(value)
          })
          .catch((reason) => {
            // TODO implement error handling
            console.log(reason)
            reject()
          })
      })
    },
    delete({ rootState }, { id, url }) {
      return new Promise((resolve, reject) => {
        rootState.authentication.currentUser
          .getIdToken()
          .then((value) => {
            return fetch(`${process.env.VUE_APP_API_URL}${url}/${id}`, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + value,
              },
            })
          })
          .then((value) => {
            resolve(value)
          })
          .catch((reason) => {
            // TODO implement error handling
            console.log(reason)
            reject()
          })
      })
    },
    get({ rootState }, { id, url }) {
      return new Promise((resolve, reject) => {
        rootState.authentication.currentUser
          .getIdToken()
          .then((value) => {
            return fetch(`${process.env.VUE_APP_API_URL}${url}/${id}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + value,
              },
            })
          })
          .then((value) => {
            if (value.status == '200') {
              return value.json()
            }
            return Promise.reject(value)
          })
          .then((value) => {
            resolve(value)
          })
          .catch((reason) => {
            // TODO implement error handling
            console.log(reason)
            reject()
          })
      })
    },
    patch({ rootState }, { body, id, url }) {
      return new Promise((resolve, reject) => {
        rootState.authentication.currentUser
          .getIdToken()
          .then((value) => {
            return fetch(`${process.env.VUE_APP_API_URL}${url}/${id}`, {
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + value,
              },
              body: JSON.stringify(body),
            })
          })
          .then((value) => {
            resolve(value)
          })
          .catch((reason) => {
            // TODO implement error handling
            console.log(reason)
            reject()
          })
      })
    },
    put({ rootState }, { body, id, url }) {
      return new Promise((resolve, reject) => {
        rootState.authentication.currentUser
          .getIdToken()
          .then((value) => {
            return fetch(`${process.env.VUE_APP_API_URL}${url}/${id}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + value,
              },
              body: JSON.stringify(body),
            })
          })
          .then((value) => {
            resolve(value)
          })
          .catch((reason) => {
            // TODO implement error handling
            console.log(reason)
            reject()
          })
      })
    },
  },
  modules: {},
}
