// #region import
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/plugins/store'
// #endregion

// #region use
Vue.use(VueRouter)
// #endregion

// #region routes
const routes = [
  {
    path: '/',
    name: 'admin',
    component: () => import(/* webpackChunkName: "vAdmin" */ '@/views/vAdmin.vue'),
    meta: {
      currentUser: true,
    },
  },
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "vUser" */ '@/views/vUser.vue'),
  },
  {
    path: '/media-library',
    name: 'media-library',
    component: () => import(/* webpackChunkName: "vMediaLibrary" */ '@/views/vMediaLibrary.vue'),
    meta: {
      // currentUser: true,
      //TODO make sure it's a guest somehow
    },
  },
  {
    path: '/editor',
    name: 'editor',
    component: () => import(/* webpackChunkName: "vEditor" */ '@/views/vEditor.vue'),
    meta: {
      // currentUser: true,
    },
  },
  {
    path: '/*',
    name: '404',
    component: () => import(/* webpackChunkName: "vError" */ '@/views/vError.vue'),
    beforeEnter() {
      window.location.replace('https://www.lasting-wedding.com/404')
    },
  },
]
// #endregion

// #region init
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
// #endregion

// #region navigation guards
router.beforeEach((to, _, next) => {
  if (to.meta.currentUser) {
    if (!store.state.authentication.currentUser) {
      next(`/user?mode=signIn&redirect=${to.fullPath}`)
    } else if (!store.state.authentication.currentUser.emailVerified) {
      next(`/user?mode=signIn&redirect=${to.fullPath}`)
    } else if (store.state.authentication.profile._deleted) {
      next(`/user?mode=deleted&redirect=${to.fullPath}`)
    } else {
      next()
    }
  } else {
    next()
  }
  // if (to.meta.currentUser && !store.state.authentication.currentUser) {
  //   next(`/user?mode=signIn&redirect=${to.fullPath}`)
  // } else {
  //   if (to.meta.currentUser && !store.state.authentication.currentUser.emailVerified) {
  //     next(`/user?mode=emailRefute&redirect=${to.fullPath}`)
  //   } else {
  //     // if (to.meta.currentUser && !store.state.authentication.profile._deleted)
  //     next()
  //   }
  // }
})
// #endregion

export default router
