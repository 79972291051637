import { getDownloadURL, uploadBytesResumable, getStorage, ref } from 'firebase/storage'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    uploadToStorage(_, { file, reference }) {
      return new Promise((resolve, reject) => {
        const uploadTask = uploadBytesResumable(ref(getStorage(), reference), file)
        uploadTask.on('state_changed', null, reject, () => {
          resolve(getDownloadURL(ref(getStorage(), reference)))
        })
      })
    },
  },
  modules: {},
}
