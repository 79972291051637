import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from './en.json'
import no from './no.json'

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: en,
    no: no,
  },
})
