// #region import
import { SET_APP, SET_CURRENT_USER, SET_PROFILE } from './mutations'
import { initializeApp } from 'firebase/app'

// prettier-ignore
import { 
  applyActionCode,
  browserLocalPersistence,
  browserSessionPersistence,
  checkActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getAuth,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  updatePassword,
  verifyPasswordResetCode,
  // eslint-disable-next-line
  deleteUser
} from 'firebase/auth'
// #endregion

// #region store
export default {
  // #region state
  state: {
    app: undefined,
    currentUser: undefined,
    profile: undefined,
  },
  // #endregion
  // #region getters
  getters: {},
  // #endregion
  // #region mutations
  mutations: {
    [SET_APP](state, payload) {
      state.app = payload
    },
    [SET_CURRENT_USER](state, payload) {
      state.currentUser = payload
    },
    [SET_PROFILE](state, payload) {
      state.profile = payload
    },
  },
  // #endregion
  // #region actions
  /**
   * Returns a JSON Web Token (JWT) used to identify the user to a Firebase service.
   * @fires getIdToken
   * @async
   */
  actions: {
    getIdToken({ state }) {
      return new Promise((resolve, reject) => {
        state.currentUser
          .getIdToken()
          .then((value) => {
            resolve(value)
          })
          .catch((reason) => {
            reject(reason)
          })
      })
    },
    /**
     * Initialize the Store.
     * @fires initializeApp
     * @fires onAuthStateChanged
     * @async
     */
    initializeStore({ state, commit, dispatch }) {
      return new Promise((resolve, reject) => {
        if (!state.app) {
          commit(SET_APP, initializeApp(JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG)))
          const unsubscribe = onAuthStateChanged(getAuth(), () => {
            unsubscribe()
            onAuthStateChanged(getAuth(), (user) => {
              if (user) {
                commit(SET_CURRENT_USER, user)
                dispatch('getProfile')
                  .then(() => {
                    resolve()
                  })
                  .catch(reject)
              } else {
                resolve()
              }
            })
          })
        } else {
          reject()
        }
      })
    },
    /**
     * Gets the current user profile from the database.
     * @async
     */
    getProfile({ state, commit, dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('get', {
          id: state.currentUser.uid,
          url: '/users',
        })
          .then((value) => {
            commit(SET_PROFILE, value)
            resolve()
          })
          .catch((reason) => {
            console.log(reason)
            reject()
          })
      })
    },
    /**
     * Sets the authentication state persistence.
     * @fires setPersistence
     * @async
     */
    setPersistence(_, persistence) {
      switch (persistence) {
        case 'local':
          return setPersistence(getAuth(), browserLocalPersistence)
        case 'session':
          return setPersistence(getAuth(), browserSessionPersistence)
        default:
          return Promise.resolve()
      }
    },
    /**
     * Creates a new user.
     * @fires createUserWithEmailAndPassword
     * @fires sendEmailVerification
     * @async
     */
    createUserWithEmailAndPassword({ state, dispatch }, { name, email, password }) {
      return new Promise((resolve, reject) => {
        createUserWithEmailAndPassword(getAuth(), email, password)
          .then(() => {
            return sendEmailVerification(state.currentUser).then(() => {
              //TODO move into a trigger service function
              return dispatch('put', {
                body: {
                  name: name,
                  tutorial: {
                    home: false,
                    editor: false,
                  },
                  updates: true,
                  newsletter: true,
                  _todos: 0,
                  _notes: 0,
                  _guests: 0,
                },
                id: state.currentUser.uid,
                url: '/users',
              }).then(() => {
                resolve()
              })
            })
          })
          .catch((reason) => {
            reject(reason)
          })
      })
    },
    /**
     * Sends an email verification to the currently logged in user.
     * @fires sendEmailVerification
     * @async
     */
    sendEmailVerification({ state }) {
      return new Promise((resolve, reject) => {
        sendEmailVerification(state.currentUser)
          .then((value) => {
            resolve(value)
          })
          .catch((reason) => {
            reject(reason)
          })
      })
    },
    /**
     * Signing a user in.
     * @fires signInWithEmailAndPassword
     * @async
     */
    signInWithEmailAndPassword({ dispatch }, { email, password }) {
      return new Promise((resolve, reject) => {
        signInWithEmailAndPassword(getAuth(), email, password)
          .then(() => {
            return dispatch('getProfile')
          })
          .then((value) => {
            resolve(value)
          })
          .catch((reason) => {
            reject(reason)
          })
      })
    },
    /**
     * Signing a user out.
     * @fires signOut
     * @async
     */
    signOut({ commit }) {
      return new Promise((resolve, reject) => {
        signOut(getAuth())
          .then((value) => {
            commit(SET_CURRENT_USER, undefined)
            commit(SET_PROFILE, value)
            resolve(value)
          })
          .catch((reason) => {
            reject(reason)
          })
      })
    },
    /**
     * Deletes a user.
     * @fires deleteUser
     * @async
     */
    deleteUser() {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        resolve()
        // deleteUser(getAuth())
        //   .then((value) => {
        //     resolve(value)
        //   })
        //   .catch((reason) => {
        //     reject(reason)
        //   })
      })
    },

    /**
     * Sends a password reset email to a user.
     * @fires sendPasswordResetEmail
     * @async
     */
    sendPasswordResetEmail(_, { email }) {
      return new Promise((resolve, reject) => {
        sendPasswordResetEmail(getAuth(), email)
          .then((value) => {
            resolve(value)
          })
          .catch((reason) => {
            reject(reason)
          })
      })
    },
    /**
     * Verifies and confirms a password reset.
     * @fires verifyPasswordResetCode
     * @fires confirmPasswordReset
     * @async
     */
    confirmPasswordReset(_, { oobCode, newPassword }) {
      return new Promise((resolve, reject) => {
        verifyPasswordResetCode(getAuth(), oobCode)
          .then(() => {
            return confirmPasswordReset(getAuth(), oobCode, newPassword)
          })
          .then((value) => {
            resolve(value)
          })
          .catch((reason) => {
            reject(reason)
          })
      })
    },
    /**
     * Performs a check for an oobCode.
     * @fires checkActionCode
     * @async
     */
    checkActionCode(_, { oobCode }) {
      return new Promise((resolve, reject) => {
        checkActionCode(getAuth(), oobCode)
          .then((value) => {
            resolve(value)
          })
          .catch((reason) => {
            reject(reason)
          })
      })
    },
    /**
     * Applies an oobCode.
     * @fires applyActionCode
     * @async
     */
    applyActionCode(_, oobCode) {
      return new Promise((resolve, reject) => {
        applyActionCode(getAuth(), oobCode)
          .then((value) => {
            resolve(value)
          })
          .catch((reason) => {
            reject(reason)
          })
      })
    },
    /**
     * Updates a user email address.
     * @fires reauthenticateWithCredential
     * @fires updateEmail
     * @async
     */
    updateEmail({ state }, { password, newEmail }) {
      return new Promise((resolve, reject) => {
        reauthenticateWithCredential(state.currentUser, EmailAuthProvider.credential(state.currentUser.email, password))
          .then(() => {
            return updateEmail(state.currentUser, newEmail)
          })
          .then((value) => {
            resolve(value)
          })
          .catch((reason) => {
            reject(reason)
          })
      })
    },
    /**
     * Updates a user password.
     * @fires reauthenticateWithCredential
     * @fires updatePassword
     * @async
     */
    updatePassword({ state }, { password, newPassword }) {
      return new Promise((resolve, reject) => {
        reauthenticateWithCredential(state.currentUser, EmailAuthProvider.credential(state.currentUser.email, password))
          .then(() => {
            return updatePassword(state.currentUser, newPassword)
          })
          .then((value) => {
            resolve(value)
          })
          .catch((reason) => {
            reject(reason)
          })
      })
    },
  },
  // #endregion
  // #region modules
  modules: {},
  // #endregion
}
// #endregion
