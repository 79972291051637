// #region import
import Vue from 'vue'
import Vuex from 'vuex'
import { ADD_ALERT } from './mutations'

import authentication from './authentication'
import api from './api'
import storage from './storage'
// #endregion

// #region use
Vue.use(Vuex)
// #endregion

// #region store
export default new Vuex.Store({
  // #region state
  state: {
    alerts: [],
  },
  // #endregion
  // #region getters
  getters: {},
  // #endregion
  // #region mutations
  mutations: {
    [ADD_ALERT](state, payload) {
      state.alerts.push(payload)
    },
  },
  // #endregion
  // #region actions
  actions: {
    /**
     * @typedef {object} Alert
     * @property {('primary' | 'secondary' | 'danger' | 'warning')} variant The alert box variant.
     * @property {number} duration The time in seconds to display the alert.
     * @property {string} message The message to display in the alert box.
     */
    /**
     * Add an alert to the stack.
     * @param {Alert} payload
     */
    addAlert({ commit }, payload) {
      commit('ADD_ALERT', payload)
    },
  },
  // #endregion
  // #region modules
  modules: {
    authentication,
    api,
    storage,
  },
  // #endregion
})
// #endregion
