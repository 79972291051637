// #region import
import Vue from 'vue'
import App from './App.vue'
import VueMeta from 'vue-meta'
import VueTour from 'vue-tour'
import router from '@/plugins/router'
import store from '@/plugins/store'
import i18n from '@/plugins/i18n'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './assets/scss/app.scss'
// #endregion

// #region use
Vue.use(VueMeta)
Vue.use(VueTour)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.config.productionTip = false
// #endregion

// #region init
store
  .dispatch('initializeStore')
  .then(() => {
    new Vue({
      router,
      store,
      i18n,
      render: (h) => h(App),
    }).$mount('#app')
  })
  .catch((reason) => {
    //TODO
    alert(reason)
    // window.location.replace('')
  })
// #endregion
